<template>
  <div class="home">
      <div class="card is-shadowless is-bordered" style="border: 2px dashed rgba(0,0,0,0.13);">
        <div class="card-content" v-if="settings!=null">
          <!--          <p>{{ $t('notVerified') }}</p>-->
          <p>{{ settings.welcome }}</p>
        </div>
      </div>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: 'Home',
  components: {HomeWrapper, VuePdfEmbed},
  data() {
    return {
      settings: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.settings = await this.$api.loadSettings();
    }
  }
}
</script>
